%clearfix {
	&:before,
	&:after {
		content: ' ';
		display: table;
	}

	&:after {
		clear: both;
	}
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.u-text-black {
	color: #000;
}

.u-text-white {
	color: #fff !important;
}

.u-full-width {
	width: 100%;
}
