.c-section {
    min-height: 100vh;
    position: relative;

    @include min-width(medium) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &--medium-50 {
            width: 50%;
        }
    }

    &--blue {
        background: linear-gradient(rgba(#D3F3FF, 0.5) 0%, rgba(#8DCFEA, 0.5) 100%);
    }

    @include max-width(medium) {
        &--triangle {
            clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0% 90%);
            padding-bottom: rem(100px);
        }
    }

    &__bittrust-logo {
        text-align: center;

        @include min-width(medium) {
            position: absolute;
            left: 50%;
            top: rem(80px);

            & + .o-headline {
                margin-top: 0;
            }
        }
    }

    &--cards {
        min-height: 0;
        position: relative;

        .c-section__inner {
            position: relative;
            z-index: 1;
        }
    }

    &__inner {
        margin: 0 auto;
        max-width: rem(530px);
        padding: rem(40px);

        &--left-side {
            max-width: rem(940px);
            padding-right: 40%;
        }

        // Vertical Rhythm
        > * + *:not(p) {
            margin-top: rem(40px);
        }
    }
}

#bitcoins-einloesen {
    min-height: auto;
    // min-height: 120vh;

    @include min-width(medium) {
        min-height: 100vh;
    }
}
