.o-card {
    background-color: #fff;
    border-radius: rem(8px);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10), 0 2px 10px 0 rgba(0,0,0,0.10);
    padding: rem(20px);

    &--max-width-350 {
        max-width: rem(350px);
    }

    &__footer {
        margin-top: rem(30px);
    }

    &__headline {
        font-family: 'AcherusGrotesque-Black', sans-serif;
        font-size: rem(14.4px);
        margin-bottom: rem(12.2px);
    }

    &__hr {
        border: 0;
        height: 1px;
        background: rgba(0,0,0,0.06);
        margin: rem(20px 0);
    }
}
