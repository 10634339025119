.o-lightbox {
    background-color: #fff;
    left: 0;
    height: 100%;
    padding: rem(40px);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: z('modal');
    overflow-y: auto;

    &__inner {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        max-width: rem(460px);
        // min-height: 100%; Darstellungfehler
    }

    &__header {
        display: flex;
        margin-bottom: rem(40px);
        width: 100%;
    }

    &__steps {
        font-size: 0;
        list-style-type: none;

        &-item {
            border-radius: rem(30px);
            border: 2px solid rgba(0,0,0,0.05);
            display: inline-block;
            font-family: 'AcherusGrotesque-Black', sans-serif;
            font-size: rem(10.8px);
            height: rem(30px);
            line-height: rem(30px);
            margin: rem(0 7.5px);
            text-align: center;
            width: rem(30px);

            &--active {
                border-color: #8DCFEB;
            }
        }
    }

    &__close {
        border-radius: rem(30px);
        border: 2px solid rgba(0,0,0,0.05);
        display: inline-block;
        font-family: 'AcherusGrotesque-Black', sans-serif;
        font-size: rem(10.8px);
        height: rem(30px);
        line-height: rem(30px);
        margin: rem(0 7.5px);
        margin-left: auto; // push the element to the right border.
        text-align: center;
        width: rem(30px);
    }

    &__content {
        margin-bottom: auto;
        margin-top: auto;
        width: 100%;

        // Vertical Rhythm
        > * + *:not(p) {
            margin-top: rem(24px);
        }

        > * + p {
            margin-top: 1em;
        }
    }
}
