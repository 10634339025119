.c-menu {
    background-color: #fff;
    border-top: 1px solid rgba(155, 155, 155, 0.15);
    bottom: 0;
    left: 0;
    padding: 0.625em 0;
    position: fixed;
    width: 100%;
    z-index: z('navigation');

    @include min-width(medium) {
        background-color: transparent;
        border-top: none;
        bottom: auto;
        left: auto;
        padding: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: auto;
    }

    &__list {
        display: flex;
        list-style-type: none;

        @include min-width(medium) {
            display: block;
        }
    }

    &__item {
        flex: 1 0 33.333333333333333%;
        padding: 0 2.933333333333333%;

        @include min-width(medium) {
            margin: rem(16px 0);
            width: 100%;

            &:nth-child(2) {
                display: none;
            }
        }
    }

    &__link {
        align-items: center;
        background-color: rgba(255,255,255,0.75);
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05), 0 4px 10px 0 rgba(0,0,0,0.05);
        color: rgba(#9B9B9B, 0.5);
        display: flex;
        font-family: 'AcherusGrotesque-Black', sans-serif;
        font-size: rem(10.8px);
        line-height: rem(11.88px);
        padding: rem(8px 12px);

        &:hover,
        &:focus {
            background-color: rgba(255,255,255,1);
            color: #8DCFEB;
        }

        @at-root {
            .c-menu__item--active & {
                background-color: rgba(255,255,255,1);
                color: #8DCFEB;
            }
        }
    }

    &__text {
        margin-right: rem(10px);
    }

    &__icon {
        flex-shrink: 0;
        margin-left: auto;
        opacity: 0.5;
        width: rem(16px);

        @at-root {
            .c-menu__item--active & {
                opacity: 1;
            }
        }
    }
}
