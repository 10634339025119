* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 100%;
}

video {
	width: 100%;
	height: auto;
}

input,
textarea,
select,
button {
	font: inherit;
	border: none;
	color: inherit;
	line-height: inherit;
	background: none;
}

[v-cloak] {
	display: none;
}
