.c-slideshow {

	// Cards Images Silder

	&--card {
		
		position: relative;
		width: 100%;
		z-index: 1;

		&__item {
			background-position: right -170px bottom 10px, 0 0;
			background-repeat: no-repeat, repeat;
			background-size: 100%, 58px;
			cursor: grab;
			width: 100%;

			@include min-width(x-small) {
				background-position: right -200px bottom, 0 0;
				background-size: 80%, 58px;
			}

			@include min-width(medium) {
				background-position: right center, 0 0;
				background-size: 50%, 58px;
			}
		}

		&__item--15-euro {
			background-color: #F9EF71;
			background-image: url('../img/cards/portraet_15euro.svg'), url('../img/cards/pattern_15euro.svg');
			color: #128ECE;
		}

		&__item--25-euro {
			background-image: url('../img/cards/portraet_25euro.svg'), url('../img/cards/pattern_25euro.svg');
			background-color: #8DCFEB;
			color: #E54B65;
		}

		&__item--50-euro {
			background-image: url('../img/cards/portraet_50euro.svg'), url('../img/cards/pattern_50euro.svg');
			background-color: #E54B65;
			color: #F9EF71;
		}

		&__item--100-euro {
			background-image: url('../img/cards/portraet_100euro.svg'), url('../img/cards/pattern_100euro.svg');
			background-color: #fff;
			color: #11A19B;
		}

		&__item--250-euro {
			background-image: url('../img/cards/portraet_250euro.svg'), url('../img/cards/pattern_250euro.svg');
			background-color: #262626;
			color: #BDAA79;
		}

		&__item-inner {
			position: relative;

			@include min-width(x-large) {
				padding-bottom: 56.25%;
			}
		}

		&__content {
			padding: rem(40px);

			@include min-width(medium) {
				max-width: rem(460px);
			}

			@include min-width(x-large) {
				box-sizing: content-box;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-100%, -50%);
			}
		}

		&__title {
			color: currentColor;
		}

		&__motive {
			// box-shadow: 0 0 4em .5em rgba(0, 0, 0, .2);
			display: block;
			filter: drop-shadow(0 0 30px rgba(0, 0, 0, .4));
			margin-top: 2em;
			max-width: 90%;
			width: 100%;

			@include min-width(medium) {
				max-width: rem(420px);
			}
		}

		&__navigation {
			font-size: 1.25em;
			margin-top: 2em;

			& > * {
				display: inline-block;
				vertical-align: middle;
			}
		}

		&__prev,
		&__next {
			background-image: svg("arrow-grey", "path[stroke]: #000; path[fill]: transparent");
			background-repeat: no-repeat;
			background-size: contain;
			height: 20px;
			width: 20px;

			&:focus {
				outline: none;
			}

			.c-slideshow--card__item--25-euro &,
			.c-slideshow--card__item--50-euro &,
			.c-slideshow--card__item--250-euro & {
				background-image: svg("arrow-grey", "path[stroke]: #fff; path[fill]: transparent");
			}
		}

		&__prev {
			margin-right: 1.5em;
			transform: rotate(180deg);
		}

		&__next {
			margin-left: 1.5em;
		}

		.slick-disabled {
			opacity: .25;
			pointer-events: none;
		}
	}
}

//  Lightbox Slideshow for Copay

.c-slideshow--lightbox {
    margin: 0 auto;
    padding: 0 34px;
    position: relative;

    // iPhone 6
    @include min-width(375px) {
        max-width: 295px;
        padding: 0 54px;
    }

    @include min-width(600px) {
        max-width: 460px;
    }

    img {
        display: block;
        width: 100%;
    }

    .slick-prev,
    .slick-next {
        background: url('../img/icons/arrow-grey.svg') no-repeat 50% 50%;
        height: 24px;
        margin-top: -12px;
        overflow: hidden;
        outline: 0;
        position: absolute;
        text-indent: 9999px;
        top: 168px;
        white-space: nowrap;
        width: 24px;

        @include min-width(600px) {
            top: 314px;
        }

        &.slick-disabled {
            opacity: 0.5;
        }
    }

    .slick-prev {
        transform: rotateY(-180deg);
        left: 0;
    }

    .slick-next {
        right: 0;
    }

    &__headline {
        font-size: rem(18px);
        font-family: 'AcherusGrotesque-Light', sans-serif;
        font-weight: normal;
        line-height: rem(21.6px);
        margin-top: rem(26px);
        text-align: center;
    }

    &__paragraph {
        font-size: rem(14.4px);
        line-height: rem(21.6px);
        margin-top: rem(10.6px);
        text-align: center;
    }
}

.c-slideshow__item {
	background-color: #fff;
	width: 172px;

	// iPhone 6
	@include min-width(375px) {
		width: 187px;
	}

	@include min-width(600px) {
		width: 352px;
	}
}
