.c-currency-rate {

    &__calculator {
        align-items: center;
        display: flex;
    }

    &__item {
        color: #4A4A4A;
        font-family: 'AcherusGrotesque-Light', sans-serif;
        font-size: rem(26px);
        line-height: rem(26px);

        &--hidden {
            display: none;
        }
    }

    &__pipe {
        color: #4A4A4A;
        font-family: 'AcherusGrotesque-Light', sans-serif;
        font-size: rem(26px);
        line-height: rem(26px);
        margin: rem(0 5px);
    }

    &__symbol {
        color: #5BC5F2;
        font-size: rem(10.8px);
        font-weight: bold;
        vertical-align: text-top;
    }

    &__switch {
        margin-left: auto;
        outline: 0;

        svg {
            display: block;
        }
    }

}
