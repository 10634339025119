.o-form {
    &__input,
    &__button {
        appearance: none;
        border: 2px solid rgba(0,0,0,0.1);
        border-radius: rem(8px);
        height: rem(45px);
        margin: rem(0 0 10px);
        padding: rem(0 16px);
        width: 100%;
        font-size: rem(14.4px);
        font-family: 'AcherusGrotesque-Medium',sans-serif;
    }

    &__checkbox-wrapper {
        color: #5C5C5C;
        font-size: rem(10.8px);
        line-height: rem(16.2px);
        margin: rem(10px 0);

        a:link,
        a:visited {
            color: #8DCFEB;
            text-decoration: underline;
        }
    }

    &__checkbox {
        appearance: none;
        background: transparent;
        border-radius: 0;
        border: none;
        float: left;
        font-size: 1em;
        margin-right: .75em;
        width: auto;
    }

    &__checkbox:checked,
    &__checkbox:not(:checked) {
        background: transparent;
        position: relative;
        visibility: hidden;
        margin: 0;
        padding: 0;
    }

    &__checkbox + label {
        cursor: pointer;
    }

    &__checkbox:checked + label::before,
    &__checkbox:not(:checked) + label::before {
        content: '';
        display:inline-block;
        width: 17px;
        height:17px;
        position: relative;
        top:4px;
        border: 2px solid #E5E5E5;
        background: white;
        margin-right: 1em;
    }

    &__checkbox:hover  + label::before {
        background:#ddd;
        box-shadow: inset 0 0 0 2px white;
    }

    &__checkbox:checked  + label::before {
        background: #8DCFEB;
        box-shadow: inset 0 0 0 2px white;
    }

    &__button {
        border-color: #E54B65;
        color: #E54B65;
        font-family: 'AcherusGrotesque-Black',sans-serif;
        margin: rem(10px 0 0);
    }
}
