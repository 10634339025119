 .o-paragraph {
    color: #4A4A4A;
    font-size: rem(18px);
    line-height: rem(27px);

    &--small {
        font-size: rem(14.4px);
        line-height: rem(21.6px);
    }

    &--center {
        text-align: center;
    }
 }
