.o-headline {
    color: #283534;
    font-family: 'AcherusGrotesque-Black', sans-serif;
    font-weight: normal;
    margin-bottom: rem(30px);

    &--1 {
        font-size: rem(48px);
    }

    &--2 {
        font-size: rem(34px);
    }

    &--3 {
        font-family: 'AcherusGrotesque-Light', sans-serif;
        font-size: rem(26px);
        line-height: rem(28.6px);
        margin-bottom: rem(20.8px);
    }

    &--primary {
        // color: #8DCFEB;
        color: #E54B65;
    }

    &--secondary {
        color: #128ECE;
    }

    &--word-break {
        word-break: break-all;
        hyphens: auto;
    }
}
