.o-button {
    border-radius: 8px;
    border: 2px solid #E54B65;
    color: #E54B65;
    font-family: 'AcherusGrotesque-Black', sans-serif;
    font-size: rem(14.4px);
    line-height: rem(31.2px);
    padding: rem(6px 18px);

    &--full-width {
        display: block;
        width: 100%;
    }

    &--full-width + &--full-width {
        margin-top: rem(10px);
    }

    &--default {
        border-color: rgba(#9B9B9B, 0.5);
        color: rgba(#9B9B9B, 0.5);
    }

    &--muted {
        opacity: 0.5;
    }

    &--icon {
        align-items: center;
        display: flex;

        &__icon * {
            display: block;
        }

        // Icon right
        &__text + &__icon {
            margin-left: auto;
            padding-left: rem(14px);
        }

        // Icon left
        &__icon + &__text {
            margin-left: auto;
            padding-left: rem(14px);
        }
    }
}
