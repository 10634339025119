body {
	color: #4A4A4A;
	font-family: $primary-font;
	line-height: $base-line-height;
}

::selection {
  background: $black;
  color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
}

a {
	color: inherit;
	text-decoration: none;
}

p a {
	color: #8DCFEB;
	text-decoration: none;
	text-shadow: 0.05em 0 0 #fff, -0.05em 0 0 #fff, 0 0.05em 0 #fff, 0 -0.05em 0 #fff, 0.1em 0 0 #fff, -0.1em 0 0 #fff, 0 0.1em 0 #fff, 0 -0.1em 0 #fff;
	background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
	background-repeat: repeat-x;
	background-position: bottom 0 center;
	background-size: 100% 1px;
}
