.o-map {
    height: 65vh;
    margin: rem(0 auto 20px);
    width: calc(100% - 2.5rem);

    @include min-width(medium) {
        margin: 0 auto;
    }

    &__headline {
        font-family: 'AcherusGrotesque-Light', sans-serif;
        font-size: rem(18px);
    }

    &__paragraph {
        font-family: 'AcherusGrotesque-Medium', sans-serif;
        font-size: rem(10.8px);
        line-height: rem(14.4px);
    }
}
