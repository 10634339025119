// A map of z layers
// Used in: 2-utilities/_z-index.scss

$z-layers: (
	'modal':            9000,
	'overlay':          8000,
	'dropdown':         7000,
	'navigation':       6000,
	'footer':           5000,
	'tooltip':          4000,
	'header':           1000,
	'default':             1,
	'bottomless-pit':  -9000,
);