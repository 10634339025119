.c-footer {
    border-top: 1px solid rgba(155, 155, 155, 0.15);
    margin-top: rem(20px);
    width: 100%;
    @include min-width(medium) {
        margin-top: rem(40px);
    }

    &__inner {
        margin: 0 auto;
        max-width: rem(960px);
        padding: rem(20px 40px);
        @include min-width(medium) {
            padding: rem(40px);
        }
    }

    p {
        color: rgba(#4A4A4A, 0.7);
        font-size: rem(13px);
        line-height: rem(18px);
    }
    
    p + p {
        margin: 1em 0 0;
    }
}
