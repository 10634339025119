/*

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/AvenirNextLTPro-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/AvenirNextLTPro-Bold.otf')  format('opentype'),
       url('../fonts/AvenirNextLTPro-Bold.woff') format('woff'),
       url('../fonts/AvenirNextLTPro-Bold.ttf')  format('truetype'),
       url('../fonts/AvenirNextLTPro-Bold.svg#AvenirNextLTPro-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

*/